import toast from "react-hot-toast";
import { kickUser } from "./userhandler";

export const errorHandler = async (error) => {
  const defaultMessage = "Something is not right.!!";
  const defaultError = {
    401: "Access Denied.!!!",
    403: "Access Denied.!!!",
    404: "Not Found.!!!",
    422: "Invalid Data.!!!",
    429: "Too Many Request.!!!",
    400: "Bad Request.!!!",
    500: "Internal Server Error.!!!",
    502: "Could not fetch data from remote.!!!",
  };
  const status_code = error && error.status;
  const message =
    error?.data?.data?.[0] ||
    error?.data?.message ||
    error?.message ||
    defaultMessage;
console.log(message,"messagemessage");
  switch (status_code) {
    case 401 || 403:
      await kickUser();
      return toast.error(message || defaultError[status_code]);
    default:
      return toast.error(message);
  }
};
