import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { getInitials } from "./get-initials";
import JSZip from "jszip";
import moment from "moment";
let array1 = [];
let array2 = [];
let array3 = [];
let array4 = [];
let array5 = [];
let array6 = [];

export const Badge = ({
  name,
  id,
  width = "40px",
  height = "40px",
  fontSize = "16px",
}) => {
  const theme = useTheme();
  useEffect(() => {
    randomColor();
  }, []);
  const randomColor = () => {
    let a = 0;
    let b = 1;
    let c = 2;
    let d = 3;
    let e = 4;
    let f = 5;

    for (let i = length || 100; i >= 0; i -= 4) {
      array1.push(a + i);
      array2.push(b + i);
      array3.push(c + i);
      array4.push(d + i);
      array5.push(e + i);
      array6.push(f + i);
    }
  };

  var BackgroundColor =
    (array1.some((data) => data == id) && "#0C0D85") ||
    (array2.some((data) => data == id) && "#8A8D93") ||
    (array3.some((data) => data == id) && "#FF4C51") ||
    (array4.some((data) => data == id) && "#FFB400") ||
    (array5.some((data) => data == id) && "#56CA00") ||
    (array6.some((data) => data == id) && "#16B1FF");

  const styles = {
    state: {
      width: width,
      height: height,
      borderRadius: "60%",
      backgroundColor: BackgroundColor || "#00A9FF",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      padding: "10px",
      color: "#ffff",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "uppercase",
      fontSize: fontSize,
    },
  };
  return <span style={styles.state}>{getInitials(name)}</span>;
};

export const sliceContent = (str, size) => {
  if (str) {
    let len = str.length;
    if (len > size) {
      return str.slice(0, size) + "...";
    } else {
      return str;
    }
  } else {
    return str;
  }
};

export function presence(string) {
  if (string === "undefined") {
    return "-";
  }
  return string?.length > 0 ? string : "-";
}

export function presenceInt(number) {
  if (number === undefined) {
    return 0;
  }
  return number;
}

export function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

export const RoleColor = (value) => {
  const categoryColors = {
    secondary: ["english", "spanish", "static", "button"],
    success: [
      "video",
      "approved",
      "public",
      "responded",
      "english",
      "Enabled",
      "paid",
      "single",
    ],
    warning: ["multi", "pending", "direct", "engagement", "dynamic"],
    question: ["text"],
    explainer: ["star", "explainer", "spanish"],
    product: ["scale"],
    primary: ["private", "shared"],
    info: ["explainer", "video", "meta"],
    error: ["deleted", "Disabled", "unpaid"],
  };

  const defaultColor = "info";

  for (const category in categoryColors) {
    if (categoryColors[category].includes(value)) {
      return category;
    }
  }

  return defaultColor;
};

export const downloadEmptyCSV = () => {
  const csvContent = "";
  const blob = new Blob([csvContent], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Empty-Respose.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getAllKeys = (arr) => {
  const values = {};
  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (!values[key]) {
        values[key] = [];
      }
      if (!values[key].includes(obj[key])) {
        values[key].push(obj[key]);
      }
    });
  });
  return values;
};

export const jsonToCsv = (report) => {
  const header = Object.getOwnPropertyNames(getAllKeys(report));
  const csvData = report.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
  );
  csvData.unshift(header.join(","));
  return csvData.join("\n");
};

export const convertCsvData = (report) => {
  let csvContent = jsonToCsv(report);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  return blob;
};

export const fetchCsvData = (report, fileName) => {
  let csvContent = jsonToCsv(report);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = fileName
    ? fileName
    : `ResponseReport-${moment().format("MM-DD-YYYY")}.csv`;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const handleDownloadZip = async (csvFiles) => {
  const zip = new JSZip();

  csvFiles.forEach((file, index) => {
    zip.file(`file${index + 1}.csv`, file.content);
  });

  try {
    const content = await zip.generateAsync({ type: "blob" });
    const url = URL.createObjectURL(content);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Inbox-Response-${moment().format("MM-DD-YYYY")}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating zip file:", error);
    // Handle error
  }
};

export const linkRegex =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
// /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))([a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/.*))?$/gm;

export const CrmLinkRegex =
  /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))([a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/.*))?$/gm;

export const handleKeyPressPhone = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const regex = /^[0-9.]+$/;
  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};
