import connection from ".";
import endpoints from "configs/endpoints";

export const loginAPI = async ({ body }) => {
  return connection.put(endpoints.login, body);
};

export const googleLoginAPI = async (body) => {
  return connection.put("/auth/google-login", body);
};

export const profileAPI = async () => {
  return connection.get(endpoints.userProfile);
};

export const invoiceListAPI = async () => {
  return connection.get("/users/subscriptions/invoice");
};

export const forgotPasswordAPI = async (body) => {
  return connection.put("/auth/forgot-password", body);
};

export const forgotPasswordVerifyAPI = async (body) => {
  return connection.put("/auth/forgot-password/verify-otp", body);
};

export const otpVerificationAPI = async (body) => {
  return connection.put("/v1/users/verify_otp", body);
};

export const resetPasswordAPI = async (body) => {
  return connection.put("/v1/users/reset_password", body);
};

export const profileUpdateAPI = async (body) => {
  return connection.put("/users/profile", body);
};

export const profileSettingsAPI = async (body) => {
  return connection.put("/users/profile-settings", body);
};

export const changePasswordAPI = async (body) => {
  return connection.put(`/v2/users/change-password`, body);
};

export const registerAPI = async (body) => {
  return connection.post("/auth/register", body);
};

export const emailVerifyAPI = async (body) => {
  return connection.put("/auth/verify-otp", body);
};

export const registerSetupAPI = async (body) => {
  return connection.post("/v2/auth/register", body);
};

export const verifyAccountsAPI = async (body) => {
  return connection.put("/v2/users/send-link", body);
};

export const verifyAccountLinkAPI = async (params) => {
  return connection.get(
    `/v2/auth/verify-link/${params?.id}?via=${params?.via}`
  );
};

export const crmConfigInfoAPI = async () => {
  return connection.get("/users/contractors/info");
};

export const crmConfigUpdateAPI = async (body) => {
  return connection.put("users/contractors/crm-settings", body);
};

export const categoryUpdateAPI = async (id, body) => {
  return connection.put(`users/responses/${id}/category`, body);
};

export const scoreConfigUpdateAPI = async (body) => {
  return connection.put("users/contractors/lead-score-settings", body);
};

export const contactConfigUpdateAPI = async (body) => {
  return connection.put("users/contractors/contact-settings", body);
};

export const crmTriggerAPI = async () => {
  return connection.put("users/contractors/send-test-request");
};

export const DeleteAccount = (body) => {
  return connection.put(`/users/delete-account/send-otp`, body);
};

export const DeleteAccountVerify = (body) => {
  return connection.put(`/users/delete-account/verify-otp`, body);
};

export const AccountNotificationAPI = (body) => {
  return connection.put(`/users/notification-settings`, body);
};

export const OrganizationAcceptInvite = async (id, body) => {
  return connection.put(`/users/${id}/join`, body);
};
