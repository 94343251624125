import "../../styles/globals.css";
import { Toaster } from "react-hot-toast";
import ReactHotToast from "utils/styles/libs/react-hot-toast";
import Spinner from "components/spinner";
import ThemeComponent from "utils/theme/ThemeComponent";
import { AuthProvider } from "context/AuthContext";
import { SettingsConsumer, SettingsProvider } from "context/settingsContext";
import UserLayout from "layouts/UserLayout";
import WindowWrapper from "components/window-wrapper";
import GuestGuard from "components/auth/GuestGuard";
import AuthGuard from "components/auth/AuthGuard";
import { SearchProvider } from "context/appBarContext";
import Head from "next/head";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import navigation from "configs/menu";

import { useAuth } from "utils/hooks/useAuth";


const Guard = ({ children, authGuard, guestGuard ,filteredMemberMenu}) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}  filteredMemberMenu={filteredMemberMenu}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<Spinner />} filteredMemberMenu={filteredMemberMenu}>{children}</AuthGuard>;
  }
};

const App = (props) => {
  const queryClient = new QueryClient();
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = props;

  const contentHeightFixed = Component.contentHeightFixed ?? false;
  const setConfig = Component.setConfig ?? undefined;
  const authGuard = Component.authGuard ?? true;
  const guestGuard = Component.guestGuard ?? false;
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>
    ));

  console.log(session, "pagePropspageProps");
  const auth = useAuth(); 
  const user = auth.user;
  const filteredMemberMenu = navigation();

  return (
    <AuthProvider>
      <Head>
        <title>AdaptiveFunnels AI</title>
        <link rel="shortcut icon" href="/images/funnels-logo.png" />
      </Head>
      <SearchProvider>
        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <QueryClientProvider client={queryClient} 
>
                  <ThemeComponent settings={settings}>
                    <WindowWrapper>
                      <GoogleOAuthProvider
                        clientId={
                          "618494239733-gk8k05vuped0uaobc0ehi3faraksbk1p.apps.googleusercontent.com"
                        }
                      >
                        <Guard authGuard={authGuard} guestGuard={guestGuard}  filteredMemberMenu={filteredMemberMenu}>
                          {getLayout(<Component {...pageProps} />)}
                        </Guard>
                      </GoogleOAuthProvider>

                      <ReactHotToast>
                        <Toaster
                          position={settings.toastPosition}
                          toastOptions={{ className: "react-hot-toast" }}
                        />
                      </ReactHotToast>
                    </WindowWrapper>
                  </ThemeComponent>
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </SearchProvider>
    </AuthProvider>
  );
};

export default App;
