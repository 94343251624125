import { titleize } from "utils/helpers";
import { useAuth } from "utils/hooks/useAuth";
import { shopHost } from "../../config";

// const adminMenu = {
//   Individual: [
//     {
//       sectionTitle: "Walkthru",
//     },
//     {
//       title: "Video Library",
//       icon: "mdi-video-box",
//       path: "/video-library",
//     },
//     {
//       title: "Walkthru",
//       icon: "mdi-file-check",
//       path: "/walkthru",
//     },
//     {
//       title: "Engagements",
//       icon: "mdi-inbox",
//       path: "/responses",
//     },
//     {
//       sectionTitle: "Admin",
//     },
//     {
//       title: "Customers",
//       icon: "mdi-contacts",
//       path: "/customer",
//     },
//     // {
//     //   title: "Team",
//     //   icon: "mdi-account-group",
//     //   path: "/team",
//     // },
//     {
//       title: "Qr Code",
//       icon: "mdi-qrcode-scan",
//       path: "/qr-code",
//     },
//     {
//       title: "Media",
//       icon: "mdi-file-video",
//       path: "/media",
//     },
//   ],
//   Team: [
//     {
//       sectionTitle: "Walkthru",
//     },
//     {
//       title: "Video Library",
//       icon: "mdi-video-box",
//       path: "/video-library",
//     },
//     {
//       title: "Walkthru",
//       icon: "mdi-file-check",
//       path: "/walkthru",
//     },
//     {
//       title: "Engagements",
//       icon: "mdi-inbox",
//       path: "/responses",
//     },

//     {
//       sectionTitle: "Admin",
//     },
//     {
//       title: "Customers",
//       icon: "mdi-contacts",
//       path: "/customer",
//     },
//     {
//       title: "Team",
//       icon: "mdi-account-group",
//       path: "/team",
//     },
//     {
//       title: "Qr Code",
//       icon: "mdi-qrcode-scan",
//       path: "/qr-code",
//     },
//     {
//       title: "Media",
//       icon: "mdi-file-video",
//       path: "/media",
//     },
//   ],
//   Enterprise: [
//     {
//       sectionTitle: "Walkthru",
//     },
//     // {
//     //   title: "Dashboard",
//     //   icon: "mdi-home",
//     //   path: "/dashboard",
//     // },
//     {
//       title: "Video Library",
//       icon: "mdi-video-box",
//       path: "/video-library",
//     },
//     // {
//     //   title: "Questionnaire",
//     //   icon: "mdi-button-pointer",
//     //   path: "/questionnaire",
//     // },
//     {
//       title: "Walkthru",
//       icon: "mdi-file-check",
//       path: "/walkthru",
//     },
//     {
//       title: "Engagements",
//       icon: "mdi-inbox",
//       path: "/responses",
//     },
//     // {
//     //   title: "Workflow",
//     //   icon: "mdi-clipboard-flow-outline",
//     //   path: "/workflow",
//     // },
//     {
//       sectionTitle: "Admin",
//     },
//     {
//       title: "Customers",
//       icon: "mdi-contacts",
//       path: "/customer",
//     },
//     {
//       title: "Team",
//       icon: "mdi-account-group",
//       path: "/team",
//     },
//     {
//       title: "Qr Code",
//       icon: "mdi-qrcode-scan",
//       path: "/qr-code",
//     },
//     // {
//     //   title: "Store",
//     //   icon: "mdi-shopping",
//     //   path: "/store",
//     // },
//     {
//       title: "Media",
//       icon: "mdi-file-video",
//       path: "/media",
//     },
//     // {
//     //   title: "Subscription",
//     //   icon: "mdi-credit-card",
//     //   path: "/subscription",
//     // },
//     // {
//     //   title: "Settings",
//     //   icon: "mdi-settings",
//     //   path: "/settings",
//     // }
//   ],
// };

// const adminMenu = [
//   {
//     sectionTitle: "Walkthru",
//   },
//   // {
//   //   title: "Dashboard",
//   //   icon: "mdi-home",
//   //   path: "/dashboard",
//   // },
//   {
//     title: "Video Library",
//     icon: "mdi-video-box",
//     path: "/video-library",
//   },
//   // {
//   //   title: "Questionnaire",
//   //   icon: "mdi-button-pointer",
//   //   path: "/questionnaire",
//   // },
//   {
//     title: "Walkthru",
//     icon: "mdi-file-check",
//     path: "/walkthru",
//   },
//   {
//     title: "Engagements",
//     icon: "mdi-inbox",
//     path: "/responses",
//   },
//   // {
//   //   title: "Workflow",
//   //   icon: "mdi-clipboard-flow-outline",
//   //   path: "/workflow",
//   // },
//   {
//     sectionTitle: "Admin",
//   },
//   {
//     title: "Customers",
//     icon: "mdi-contacts",
//     path: "/customer",
//   },
//   {
//     title: "Team",
//     icon: "mdi-account-group",
//     path: "/team",
//   },
//   {
//     title: "Qr Code",
//     icon: "mdi-qrcode-scan",
//     path: "/qr-code",
//   },
//   // {
//   //   title: "Store",
//   //   icon: "mdi-shopping",
//   //   path: "/store",
//   // },
//   {
//     title: "Media",
//     icon: "mdi-file-video",
//     path: "/media",

//   },
//   {
//     title: "Shop",
//     icon: "mdi-shopping",
//     path: `https://fd9deb-2.myshopify.com`
//   }
//   // {
//   //   title: "Subscription",
//   //   icon: "mdi-credit-card",
//   //   path: "/subscription",
//   // },
//   // {
//   //   title: "Settings",
//   //   icon: "mdi-settings",
//   //   path: "/settings",
//   // }
// ];

const memberMenu = [
  {
    sectionTitle: "Create",
  },
  {
    title: "AI Funnels",
    icon: "mdi-file-check",
    path: "/ai-funnels",
    id: "walkthru",
  },

  {
    title: "Video Library",
    icon: "mdi-video-box",
    path: "/video-library",
    id: "video-library",
  },
  // {
  //   title: "Community",
  //   icon: "mdi-earth",
  //   path: "/community",
  //   id: "community",
  // },
  {
    sectionTitle: "Engage",
  },
  {
    title: "Inbox",
    icon: "mdi-inbox",
    path: "/inbox",
    id: "engagements",
  },
  {
    title: "Contacts",
    icon: "mdi-contacts",
    path: "/contacts",
    id: "contacts",
  },
  {
    sectionTitle: "Analyze",
  },
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    path: "/dashboard",
    id: "dashboard",
  },
  {
    title: "Reports",
    icon: "mdi-chart-arc",
    path: "/reports",
    id: "reports",
  },

  // {
  //   title: "Questionnaire",
  //   icon: "mdi-button-pointer",
  //   path: "/questionnaire",
  // },

  // {
  //   sectionTitle: "Admin",
  // },

  // {
  //   title: "Workflow",
  //   icon: "mdi-clipboard-flow-outline",
  //   path: "/workflow",
  // },
  {
    sectionTitle: "Settings",
  },
  {
    title: "Forms",
    icon: "mdi-text-box-multiple",
    path: "/forms",
    id: "forms",
  },
  // {
  //   title: "Store",
  //   icon: "mdi-shopping",
  //   path: "/store",
  // },
  // {
  //   title: "Media",
  //   icon: "mdi-file-video",
  //   path: "/media",
  // },
  // {
  //   title: "Subscription",
  //   icon: "mdi-credit-card",
  //   path: "/subscription",
  // },
];

const navigation = () => {
  const auth = useAuth();
  const { user } = auth;

  const userEmail = user?.email;
  const userPhone = user?.contactNumber;

  let url = `${shopHost}/?`;

  if (userEmail) {
    url += `email=${userEmail}&`;
  }

  if (userPhone) {
    url += `phone=${userPhone}&`;
  }

  url += "cache=false";

  const adminMenu = [
    // {
    //   title: "Getting Started",
    //   icon: "mdi-rocket",
    //   path: "/getting-started",
    //   id: "getting-started",
    // },
    {
      sectionTitle: "Create",
    },

    {
      title: "AI Funnels",
      icon: "mdi-file-check",
      path: "/ai-funnels",
      id: "walkthru",
    },

    {
      title: "Video Library",
      icon: "mdi-video-box",
      path: "/video-library",
      id: "video-library",
    },
    // {
    //   title: "Community",
    //   icon: "mdi-earth",
    //   path: "/community",
    //   id: "community",
    // },

    {
      sectionTitle: "Engage",
    },
    {
      title: "Inbox",
      icon: "mdi-inbox",
      path: "/inbox",
      id: "engagements",
    },
    {
      title: "Contacts",
      icon: "mdi-contacts",
      path: "/contacts",
      id: "contacts",
    },
    {
      sectionTitle: "Analyze",
    },
    {
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      path: "/dashboard",
      id: "dashboard",
    },
    {
      title: "Reports",
      icon: "mdi-chart-arc",
      path: "/reports",
      id: "reports",
    },
    {
      sectionTitle: "Settings",
    },

    // {
    //   title: "Workflow",
    //   icon: "mdi-clipboard-flow-outline",
    //   path: "/workflow",
    // },
    // {
    //   sectionTitle: "Admin",
    // },

    {
      title: "Forms",
      icon: "mdi-text-box-multiple",
      path: "/forms",
      id: "forms",
    },
    // {
    //   title: "Qr Code",
    //   icon: "mdi-qrcode-scan",
    //   path: "/qr-code",
    //   id: "qr-code",
    // },
    {
      title: "Team",
      icon: "mdi-account-group",
      path: "/team",
      id: "team",
    },

    // {
    //   title: "Store",
    //   icon: "mdi-shopping",
    //   path: "/store",
    // },
    // {
    //   title: "Media",
    //   icon: "mdi-file-video",
    //   path: "/media",
    // },
    // {
    //   title: "Shop",
    //   icon: "mdi-shopping",
    //   path: url,
    //   openInNewTab: true,
    //   id: "shop",
    // },
    // {
    //   title: "Subscription",
    //   icon: "mdi-credit-card",
    //   path: "/subscription2",
    // },
    // {
    //   title: "Settings",
    //   icon: "mdi-settings",
    //   path: "/settings",
    // }
  ];

  const individualMenu = [
    {
      sectionTitle: "Create",
    },
    {
      title: "AI Funnels",
      icon: "mdi-file-check",
      path: "/ai-funnels",
      id: "walkthru",
    },
    {
      title: "Video Library",
      icon: "mdi-video-box",
      path: "/video-library",
      id: "video-library",
    },
    // {
    //   title: "Community",
    //   icon: "mdi-earth",
    //   path: "/community",
    //   id: "community",
    // },
    {
      sectionTitle: "Engage",
    },
    {
      title: "Inbox",
      icon: "mdi-inbox",
      path: "/inbox",
      id: "engagements",
    },
    {
      title: "Contacts",
      icon: "mdi-contacts",
      path: "/contacts",
      id: "contacts",
    },

    {
      sectionTitle: "Analyze",
    },
    {
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      path: "/dashboard",
      id: "dashboard",
    },
    {
      title: "Reports",
      icon: "mdi-chart-arc",
      path: "/reports",
      id: "reports",
    },
    {
      sectionTitle: "Settings",
    },
    {
      title: "Forms",
      icon: "mdi-text-box-multiple",
      path: "/forms",
      id: "forms",
    },
    // {
    //   title: "Qr Code",
    //   icon: "mdi-qrcode-scan",
    //   path: "/qr-code",
    //   id: "qr-code",
    // },
  ];
  const isAdmin = ["agent", "admin"].includes(
    user?.allContractors.find(
      (item) => item?.contractorId === user?.activeContractor?._id
    )?.role
  );

  const isIndividualAcc =
    user?.activeContractor?.contractorType === "individual";

  const accessMenu =
    isAdmin && isIndividualAcc
      ? individualMenu
      : isAdmin
      ? adminMenu
      : memberMenu;

  const filteredMemberMenu = accessMenu.map((menuItem) => {
    if (
      menuItem.title === "Dashboard" &&
      user?.subscription?.planName === "Basic"
    ) {
      return menuItem;
    } else {
      return menuItem;
    }
  });

  console.log(filteredMemberMenu, "filter");

  return filteredMemberMenu;
};
export default navigation;
