// ** React Imports
import React, { createContext, useEffect, useState } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** Axios
import connection from "pages/api";
import { loginAPI } from "pages/api/auth";
import endpoints from "configs/endpoints";

// ** Utils
import {
  setCookie,
  deleteAllCookie,
  getCookie,
  deleteCookie,
} from "utils/cookies";
import { googleLogout } from "@react-oauth/google";
// ** Config
import authConfig from "configs/auth";
import { errorHandler } from "utils/errorHandler";
import { toast } from "react-hot-toast";

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  roles: [],
  isLoggedIn: false,
  setIsLoggedIn: () => null,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [isLoggedIn, setIsLoggedIn] = useState(defaultProvider.isLoggedIn);
  const [roles, setRoles] = useState(defaultProvider.roles);
  const [loading, setLoading] = useState(defaultProvider.loading);
  // ** Hooks
  const router = useRouter();

  useEffect(() => {
    initAuth();
  }, []);
  const kickUser = () => {
    setLoading(true);
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem(authConfig.accessTokenKeyName);
    localStorage.removeItem(authConfig.refreshTokenKeyName);
    localStorage.removeItem(authConfig.rolesStorageKeyName);
    deleteCookie(authConfig.accessTokenKeyName);
    deleteCookie("user-id");
    setUser(null);
    setRoles(null);
    setLoading(false);

    googleLogout();
    if (!router.pathname.includes("login")) {
      router.replace("/login");
      setTimeout(() => {
        // setLoading(false);
        setIsLoggedIn(false);
      }, 500);
    }
  };
  // ** Hooks
  useEffect(() => {
    initAuth();
  }, []);
  const initAuth = async () => {
    const storedToken = getCookie(authConfig.accessTokenKeyName);
    if (storedToken) {
      await connection
        .get(endpoints.userProfile)
        .then(({ data }) => {
          window.localStorage.setItem("userData", JSON.stringify(data.data));
          setUser(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          kickUser();
        });
    }
    if (!isLoggedIn && !storedToken) {
      setLoading(false);
    }
  };

  const handleLogin = (params, errorCallback) => {
    let sentParams = {
      ...params,
      deviceId: window.navigator.userAgent,
      deviceType: "web",
    };
    loginAPI({ body: sentParams })
      .then(async ({ data }) => {
        toast.success(data.message);
        if (params.rememberMe) {
          localStorage.credential = JSON.stringify(params);
          window.localStorage.setItem("rememberMe", "true");
        }
        setCookie(authConfig.accessTokenKeyName, data.data.accessToken);

        window.localStorage.setItem("userData", JSON.stringify(data.data));
        setIsLoggedIn(true);
        setTimeout(() => {
          initAuth();
          window.localStorage.setItem(
            authConfig.refreshTokenKeyName,
            data.data.refreshToken
          );
          setTimeout(() => {
            const returnUrl = router.query.returnUrl;
            const redirectURL =
              returnUrl && returnUrl !== "/"
                ? returnUrl
                : "/ai-funnels";
            router.replace(redirectURL);
          }, 500);
        }, 1000);
      })
      .catch((error) => {
        if (errorCallback) errorCallback(error);
        if (error?.response) {
          errorHandler(error.response);
        } else {
          errorHandler(error);
        }
      });
  };

  const values = {
    user,
    roles,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: kickUser,
    profilReload: initAuth,
    isLoggedIn,
    setIsLoggedIn,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
